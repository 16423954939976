import { Ref } from "vue";
import { useRuntimeConfig, useCookie } from "#app";
import { default as Axios, AxiosRequestConfig, AxiosInstance } from "axios";
import { COOKIE_KEYS } from "../utils/constants";

interface useAxiosInstance {
  $axios: AxiosInstance;
  $request<T = any>(config: AxiosRequestConfig): Promise<T>;
  $get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  $post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T>;
  $put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T>;
  $delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  $patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T>;
  $options<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
  $head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
}

export function useAxios(): useAxiosInstance {
  const config = useRuntimeConfig();
  const axiosExtra = {};

  const authToken: Ref<string | undefined> = useCookie(COOKIE_KEYS.authToken);

  const createAxiosInstance = (axiosOptions) => {
    // Create new axios instance
    const axios = Axios.create(axiosOptions);
    // @ts-ignore
    axios.CancelToken = Axios.CancelToken;
    // @ts-ignore
    axios.isCancel = Axios.isCancel;

    // Extend axios proto
    extendAxiosInstance(axios);

    // Intercept to apply default headers
    axios.interceptors.request.use((config) => {
      let headers = { ...axios.defaults.headers.common, ...config.headers };

      if (authToken.value)
        headers = { ...headers, Authorization: authToken.value };

      config.headers = { ...config.headers, ...headers };

      config.url = encodeURI(config.url);

      return config;
    });

    return axios;
  };

  for (const method of [
    "request",
    "delete",
    "get",
    "head",
    "options",
    "post",
    "put",
    "patch",
  ]) {
    axiosExtra["$" + method] = function () {
      return this[method].apply(this, arguments).then((res) => res && res.data);
    };
  }

  const extendAxiosInstance = (axios) => {
    for (const key in axiosExtra) {
      axios[key] = axiosExtra[key].bind(axios);
    }
  };

  const axiosOptions = {
    baseURL: config?.privateApiBaseUrl ?? config.public.apiBaseUrl,
  };

  const axios = createAxiosInstance(axiosOptions);

  // @ts-ignore
  return {
    ...axios,
    $axios: axios,
  };
}
