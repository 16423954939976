import { defineStore } from "pinia";

export const useOrgStore = defineStore("organization", {
  state: (): { organization: any; } => ({
    organization: null,
  }),
  actions: {
    clearOrganization() {
      this.organization = null;
    },
    setOrganization(organization: any) {
      this.organization = organization;
    },
  },
});

