export const appCdnURL = "https://cdn.positive.ooo"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, maximum-scale=2.0"},{"hid":"title","property":"title","content":"ООО \"Позитив\""},{"hid":"og:title","property":"og:title","content":"ООО \"Позитив\""},{"hid":"description","name":"description","content":"Позитив - ваш выбор для дома и ремонта. Широкий ассортимент товаров: сантехника, стройматериалы, инструменты. Качество и доступные цены. Покупайте онлайн уже сегодня!"},{"hid":"og:description","property":"og:description","content":"Позитив - ваш выбор для дома и ремонта. Широкий ассортимент товаров: сантехника, стройматериалы, инструменты. Качество и доступные цены. Покупайте онлайн уже сегодня!"},{"name":"robots","content":"index, follow"},{"name":"keywords","content":"абхазия, сухум, мачарка, позитив, ремонт, стройка, сантехника, лаки, краски, стройматериалы, инстурменты, электрика, мебель, лакокрасочные материалы, сельхозтовары, освещение, электротовары, товары для дома, двери"},{"hid":"url","name":"url","content":"https://positive.ooo"},{"hid":"og:url","property":"og:url","content":"https://positive.ooo"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.png"}],"style":[],"script":[],"noscript":[],"title":"ООО \"Позитив\""}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false