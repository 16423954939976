<template>
  <div>
    <NuxtPage />
  </div>
</template>

<script setup>
import {
  useRecentlyViewedStore,
  useCartStore,
  useOrderStore,
  useCategoryStore,
} from "~/stores";

import { getCategoriesTree } from "~/utils/helpers";

const recentlyViewedStore = useRecentlyViewedStore();
const categoriesStore = useCategoryStore();
const favoritesStore = useFavoritesStore();
const cartStore = useCartStore();

const orderStore = useOrderStore();

orderStore.$subscribe(() => {
  localStorage.setItem(
    LOCALSTORAGE_KEYS.order,
    JSON.stringify(orderStore.order)
  );
});

cartStore.$subscribe(() => {
  localStorage.setItem(LOCALSTORAGE_KEYS.cart, JSON.stringify(cartStore.cart));
});

recentlyViewedStore.$subscribe(() => {
  localStorage.setItem(
    LOCALSTORAGE_KEYS.viewedProduct,
    JSON.stringify(recentlyViewedStore.recentlyViewed)
  );
});

onMounted(() => {
  let recentlyViewed =
    localStorage.getItem(LOCALSTORAGE_KEYS.viewedProduct) || "[]";
  recentlyViewedStore.setRecentlyViewed(JSON.parse(recentlyViewed));

  let favoriteProducts =
    localStorage.getItem(LOCALSTORAGE_KEYS.favoritesStore) || "[]";
  favoritesStore.setFavoritesStore(JSON.parse(favoriteProducts));

  let savedCart = localStorage.getItem(LOCALSTORAGE_KEYS.cart) || "{}";
  cartStore.setCart(JSON.parse(savedCart));
});

const { $get } = useAxios();
const { data: category, pending: isCategoriesLoading } = await useAsyncData(
  "get all category",
  () => $get(`/api/v1/category`)
);

categoriesStore.setCategoryStore(getCategoriesTree(category));
</script>
