import {
  defineNuxtPlugin,
  addRouteMiddleware,
  useCookie,
  navigateTo,
} from "#app";
import { COOKIE_KEYS } from "../utils/constants";
import { useUserStore } from "../stores";
import { useAxios } from "../composables/useAxios";
import { computed } from "#imports";
import { useOrgStore } from "~/stores/use-org-store";

export default async (to, from) => {
  try {
    if (to.fullPath === "/login") return true;

    const { $get } = useAxios();
    const authToken = useCookie(COOKIE_KEYS.authToken);
    const userStore = useUserStore();
    const orgStore = useOrgStore();

    const user = computed({
      get() {
        return userStore.user;
      },
      set(value: any) {
        userStore.setUser(value);
      },
    });

    const organization = computed({
      get() {
        return orgStore.organization;
      },
      set(value: any) {
        orgStore.setOrganization(value);
      },
    });

    if (!authToken.value) {
      return await navigateTo("/login");
    }
    if (!user.value) user.value = await $get("/api/v1/auth");

    switch (user.value.role) {
      case "client":
        if (user.value.organization_id)
          organization.value = await $get(
            "/api/v1/organization/" + user.value.organization_id
          );
        else return await navigateTo("/login");
        return true;
      case "admin":
        return true;
      case "sales_representative":
        return true;
      default:
        return await navigateTo("/login");
    }
  } catch (error) {
    console.error(error);
    return await navigateTo("/login");
  }
};
